import { NgClass, NgIf } from '@angular/common';
import { afterNextRender, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PrimeNGModule } from '../../primeng.module';
import { AuthService } from '../../services/auth/auth.service';
import { BudgetService } from '../../services/budget/budget.service';
import { CartService } from '../../services/cart/cart.service';
import { FreightService } from '../../services/freight/freight.service';
import { GoogleAnalyticsService } from '../../services/google/google-analytics.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../services/product/products.service';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { ToastService } from '../../services/toast/toast.service';
import { UserService } from '../../services/user/user.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { CartProductsDesktopComponent } from '../../shared/components/cart-products-desktop/cart-products-desktop.component';
import { CartProductsMobileComponent } from '../../shared/components/cart-products-mobile/cart-products-mobile.component';
import { FreightQuotesDesktopComponent } from '../../shared/components/freight-quotes-desktop/freight-quotes-desktop.component';
import { HasNoItensComponent } from '../../shared/components/has-no-itens/has-no-itens.component';
import { ZipCodeInputComponent } from '../../shared/components/zip-code-input/zip-code-input.component';
import Budget from '../../shared/models/budget/budget';
import { Cart, CartItem } from '../../shared/models/cart/cart';
import CartCheckoutDetails from '../../shared/models/cart/cart-checkout-details';
import CalculateShipping from '../../shared/models/freight/calculate-shipping';
import Carrying from '../../shared/models/freight/carrying';
import Freight from '../../shared/models/freight/freight';
import FreightSelected from '../../shared/models/freight/freight-selected';
import ItemShipping from '../../shared/models/freight/item-shipping';
import QuoteList from '../../shared/models/freight/quote-list';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import Address from '../../shared/models/user/address';
import User from '../../shared/models/user/user';
import { SharedModule } from '../../shared/shared.module';
import { validName, validPhone } from '../../shared/utils/validators';
import { TWO_SECONDS_IN_MS, wait } from '../../shared/utils/wait';

export class BudgetError {
  public errorEmail?: boolean;
  public errorName?: boolean;
  public errorPhone?: boolean;
}

export class BudgetData {
  public name?: string;
  public zipCode?: string;
  public phone?: string;
  public cartProducts?: CartItem[];
}

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    BreadcrumbComponent,
    PrimeNGModule,
    HasNoItensComponent,
    ZipCodeInputComponent,
    CartProductsMobileComponent,
    CartProductsDesktopComponent,
    SharedModule,
    FreightQuotesDesktopComponent,
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
  providers: [ToastService, MessageService],
})
export class CartComponent {
  public address?: Address;
  public budget: Budget = new Budget();
  public budgetError: BudgetError = new BudgetError();
  public cart: Cart = {};
  public cartCheckout?: CartCheckoutDetails;
  public freightSelected: FreightSelected | undefined;
  public freightError: string | undefined;
  public idCart?: string | null;
  public loadingCart = true;
  public loadingFreight = false;
  public showHasNoItens = false;
  public quotes?: QuoteList;
  public showBudget = false;
  public user: User | undefined = new User();
  public zipCode?: string;
  public initiationReady = false;
  public budgetData: BudgetData = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private budgetService: BudgetService,
    private cartService: CartService,
    private confirmationService: ConfirmationService,
    private freightService: FreightService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private localStorageService: LocalStorageService,
    private metaServices: MetaTagsService,
    private productService: ProductsService,
    public router: Router,
    public sessionStorageService: SessionStorageService,
    private toastService: ToastService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {
    afterNextRender(() => {
      this.init();
    });

    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  async init() {
    this.initiationReady = true;
  }

  async ngOnInit() {
    this.loadingCart = true;
    await this.productService.getValueMotorBando();
    this.idCart = this.activatedRoute.snapshot.paramMap.get('idCart');
    this.zipCode = await this.localStorageService.get(
      StorageConstants.RM_ECOMMERCE_ZIP_CODE
    );
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CART,
        url[0]?.path,
        '',
        '',
        null
      );
    });

    this.configCart();
  }

  async configCart() {
    try {
      const cartItems = await this.cartService.getItems(this.idCart);
      this.cart.cartProducts = cartItems.map((c) => ({ ...c }));
      this.cartService.setAuxVariables(this.cart, this.freightSelected);
      if (this.user) {
        const cart = await this.cartService.get(false);
        if (cart && cart.id > 0) {
          this.cart.id = cart.id;
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingCart = false;
      this.googleAnalyticsService.sendEventViewCart(this.cart);
      if (this.initiationReady) {
        this.showHasNoItens = !this.hasCart();
      }
    }
  }

  async calculateFreight(zipCode: string) {
    if (!zipCode) {
      return;
    }
    this.freightError = undefined;
    this.loadingFreight = true;
    this.freightSelected = undefined;
    try {
      const calculate: CalculateShipping = new CalculateShipping();
      calculate.cep = zipCode;
      if ((this.cart?.id || 0) > 0) {
        calculate.cartId = this.cart.id;
      } else {
        calculate.itens = this.cart?.cartProducts?.map((i: CartItem) => {
          return new ItemShipping(i);
        });
      }

      if (calculate?.cartId || (calculate?.itens?.length || 0) > 0) {
        const quotes: QuoteList =
          await this.freightService.calculateShippingOrCart(calculate);
        if (quotes && (quotes?.carriers?.length || 0) > 0) {
          let daysOnlyOrder = 0;
          this?.cart?.cartProducts?.forEach((i: CartItem) => {
            if ((i?.product?.daysOnlyOrder || 0) > daysOnlyOrder) {
              daysOnlyOrder = i?.product?.daysOnlyOrder || 0;
            }
          });

          if (daysOnlyOrder > 0) {
            quotes?.carriers?.forEach((carrier) => {
              if (carrier.optionFreight === Freight.STORE_PICKUP) {
                carrier.deliveryTime =
                  (carrier.deliveryTime || 0) + daysOnlyOrder;
              }
            });
          }
          this.quotes = { ...quotes };
          this.cdr.detectChanges();
        } else {
          const similarItems = this.cart?.cartProducts?.filter(
            (item: CartItem) => item?.product?.similarProduct
          );

          if (similarItems && similarItems?.length > 0) {
            this.confirmationService.confirm({
              message:
                'Há produto(s) sem entrega para sua região, porém temos ele em uma medida menor (cortado para envio). Deseja trocar para esse(s) produto(s)?',
              header: 'Confirmação',
              accept: async () => {
                similarItems.forEach(async (item) => {
                  await this.changeSimilarItem(item);
                });
              },
              reject: () => {
                this.setFreightError();
              },
            });
          } else {
            this.setFreightError();
          }
        }
      }
    } catch (err) {
      this.toastService.error('Ocorreu um erro ao consultar seu frete!');
    } finally {
      this.loadingFreight = false;
    }
  }

  async changeSimilarItem(similarItem: CartItem) {
    try {
      const product = await this.productService.get(
        similarItem?.product?.similarProduct?.toString() || ''
      );

      if (product) {
        similarItem.amount = 0;
        await this.cartService.addItem(similarItem, false, false);
        this.cart?.cartProducts?.splice(
          this.cart.cartProducts.indexOf(similarItem),
          1
        );

        const cartItem: CartItem = {
          idProduct: product.id,
          amount: 1,
          product,
        };
        await this.cartService.addItem(cartItem, true, false);
        this.cart?.cartProducts?.push(cartItem);
      }
    } catch (error) {
      this.toastService.error(
        'Ocorreu um erro ao tentar trocar o produto: ' +
          similarItem?.product?.id
      );
    }
  }

  setFreightError() {
    const msg = 'Para cotar esse frete com 6 metros, solicite via whatsapp!';
    this.toastService.error(msg);
    this.freightError = msg;
  }

  hasCart(): boolean {
    return (this?.cart?.cartProducts?.length || 0) > 0 || false;
  }

  async onClickCheckout() {
    if (this.freightSelected) {
      await this.sessionStorageService.set(
        StorageConstants.RM_ECOMMERCE_FREIGHT,
        this.freightSelected
      );
      this.userService.goToPayment();
    } else {
      this.toastService.error('Selecione um frete para gerar seu pedido!');
    }
  }

  async changeZipCode() {
    this.quotes = undefined;
    this.freightError = '';
    this.cdr.detectChanges();
  }

  async addressFound(address: Address) {
    this.address = address;
    if (address?.zipCode) {
      this.zipCode = address?.zipCode || '';
      await this.calculateFreight(address?.zipCode);
    } else {
      this.zipCode = '';
      this.toastService.error('CEP não localizado!');
    }
  }

  async setFreightSelectedDesktop(carrier: Carrying) {
    if (carrier) {
      const freightSelected = new FreightSelected(
        carrier,
        this.quotes?.tokenOffer || ''
      );
      this.freightSelected = freightSelected;
    } else {
      this.freightSelected = undefined;
    }
    this.cartService.setAuxVariables(this.cart, this.freightSelected);
  }

  async setFreightSelected(freightSelected: FreightSelected) {
    this.freightSelected = freightSelected;
    this.cartService.setAuxVariables(this.cart, this.freightSelected);
  }

  removeItem(item: CartItem) {
    this.confirmationService.confirm({
      message: 'Deseja realmente remover este item?',
      header: 'Confirmação',
      accept: async () => {
        try {
          this.cart?.cartProducts?.splice(
            this.cart.cartProducts.indexOf(item),
            1
          );
          this.cart.cartProducts = [...(this.cart.cartProducts || [])];
          item.amount = 0;
          await this.cartService.addItem(item, false, false);
          if (this.cart?.cartProducts?.length > 0) {
            this.quotes = undefined;
            this.freightError = undefined;
            this.calculateFreight(this.zipCode || '');
          }
          this.cartService.setAuxVariables(this.cart, this.freightSelected);
        } catch (err) {
          this.toastService.error('Ocorreu um erro ao remover o produto!');
        } finally {
          this.showHasNoItens = !this.hasCart();
        }
      },
    });
  }

  async setQuantity(item: CartItem) {
    const quantity = item.amount || 0;
    const stockBalance = item?.product?.stockBalance || 0;
    try {
      if (
        stockBalance < quantity &&
        !item?.product?.canBeGeneratedWithStockZero
      ) {
        const msg =
          stockBalance === 1
            ? `Existe apenas ${stockBalance} item disponível!`
            : `Existem apenas ${stockBalance} itens disponíveis!`;
        item.amount = item.amountOld;
        return this.toastService.error(
          `Quantidade informada não disponível. ${msg}`
        );
      }
      if (quantity < 1) {
        this.removeItem(item);
      } else {
        item.amount = quantity;
        item.amountOld = quantity;

        const itemCart =
          this.cart?.cartProducts?.find((i) =>
            this.cartService.productsEquals(item, i)
          ) || {};

        itemCart.amount = quantity;
        itemCart.amountOld = quantity;

        await this.cartService.addItem(item, false, false);
        this.toastService.success('Quantidade alterada com sucesso!');
        await this.calculateFreight(this.zipCode || '');
        this.cartService.setAuxVariables(this.cart, this.freightSelected);
      }
    } catch (err) {
      this.toastService.error('Erro ao adicionar quantidade!');
    } finally {
      this.showHasNoItens = !this.hasCart();
    }
  }

  onClickDeleteCart() {
    this.confirmationService.confirm({
      message: 'Deseja realmente excluir esse carrinho?',
      header: 'Confirmação',
      accept: async () => {
        this.deleteCart();
      },
    });
  }

  async deleteCart() {
    try {
      await this.cartService.delete(this.cart?.id || 0);
      this.toastService.success('Carrinho excluído com sucesso!');
      this.cart = {};
    } catch (err) {
      this.toastService.error('Erro ao excluir o carrinho!');
      throw err;
    } finally {
      this.showHasNoItens = !this.hasCart();
    }
  }

  async onClickBtCreateBudget() {
    if (!this.zipCode) {
      this.toastService.error('Digite um CEP válido para obter sua cotação!');
      return;
    }

    if (!this.validBudget()) {
      this.toastService.error('Preencha todos os campos!');
      return;
    }
    try {
      if (this.budget) {
        this.budgetData.zipCode = this.zipCode;
        this.budgetData.cartProducts = this.cart.cartProducts;
        this.budget = await this.budgetService.save(this.budgetData);
        this.toastService.success('Orçamento criado com sucesso!');
        await wait(TWO_SECONDS_IN_MS);
        window.open(`${environment.linkCart}/${this.budget?.hash}`, '_blank');
        this.budget = new Budget();
      }
    } catch (error: any) {
      this.toastService.error(error?.message || 'Erro ao criar orçamento!');
    } finally {
      this.showBudget = false;
    }
  }

  validBudget() {
    this.budgetError.errorName = !validName(this.budgetData?.name || '');
    this.budgetError.errorPhone = !validPhone(this.budgetData?.phone || '');

    const hasError = this.budgetError.errorName || this.budgetError.errorPhone;

    return !hasError;
  }
}
